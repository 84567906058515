.chooseFile {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    height: 100dvh;
    text-align: center;

    .close {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }

    .title {
        font-size: 20px;
        font-weight: 700;
        color: #d4d4d4;
    }

    .desc {
        font-size: 14px;
        color: #d4d4d4;
        line-height: normal;
    }

    .chooseBtn {
        width: 80%;

        &:hover {
            opacity: 0.8;
        }
    }
}
