.cpCoverPhoto {
    width: 100dvw;
    aspect-ratio: 352 / 292;

    @media (width >= 513px) {
        width: 512px;
        margin: 0 auto;
    }

    .imgBox {
        aspect-ratio: 352 / 292;
    }
}
