.autoMessageNotify {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    background-color: rgb(0 0 0 / 80%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;

    .box {
        background-color: #262626;
        color: #d4d4d4;
        width: 256px;
        border-radius: 16px;
        box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 50%);
        box-sizing: border-box;
        padding: 16px;
        text-align: center;
        position: relative;

        .close {
            position: absolute;
            right: 16px;
            top: 16px;
        }

        .title {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            padding: 12px 0 8px;
        }

        .meta {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: #a3a3a3;
            margin-bottom: 16px;
        }

        .btn {
            height: 44px;
            text-align: center;
            background-color: #d4d4d4;
            border-radius: 12px;
            border: none;
            width: 100%;
            margin-bottom: 8px;
        }
    }
}
