.footer {
    width: 100%;
    height: 72px;

    .wrapper {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 72px;
        display: flex;
        align-items: center;
        border-top: 1px solid #525252;
        background-color: #0a0a0a;
        box-sizing: border-box;

        @media (width >= 513px) {
            width: 512px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .footerItem {
        font-size: 12px;
        font-weight: 700;
        color: #d4d4d4;
        width: calc(100% / 3);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        position: relative;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }

        .icon {
            height: 24px;
        }

        .pointer {
            position: absolute;
            top: 0;
            background-color: #d20202;
            border-radius: 99em;
            color: #fff;
            padding: 4px;
            font-size: 12px;
            left: 0;
            right: 0;
            margin: auto;
            width: 20px;
            transform: translateX(16px);
        }
    }

    .notActive {
        opacity: 0.5;
    }
}
