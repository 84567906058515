.addModal {
    position: fixed;
    width: 100vw;
    height: 100dvh;
    background-color: #0a0a0a;
    z-index: 100;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    outline: none;

    @media (width >= 513px) {
        width: 512px;
        left: 50%;
        transform: translateX(-50%);
    }
}
