.notice {
    position: relative;

    .unread {
        position: absolute;
        right: -8px;
        top: -8px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        height: fit-content;
        min-width: 16px;
        min-height: 16px;
        border-radius: 50%;
        background-color: #ef4444;
        color: #d4d4d4;
        font-size: 12px;
        font-weight: 700;
    }
}
