.cpInfoLayout {
    display: flex;
    flex-direction: column;
    height: 100dvh;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #d4d4d4;
        padding: 8px 16px;
        height: 56px;
        box-sizing: border-box;

        .title {
            font-size: 24px;
            font-weight: 700;
            line-height: 36px;
        }

        .rightArea {
            display: flex;
            align-items: center;
            gap: 16px;

            .change {
                font-weight: 400;
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-y: auto;
        gap: 16px;

        .coverPhoto {
            position: relative;

            .coverHeader {
                position: absolute;
                top: 0;
                z-index: 9;
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding: 16px;
                box-sizing: border-box;

                .cpLabel {
                    padding: 8px;
                    display: inline-flex;
                    align-items: center;
                    column-gap: 8px;
                    color: #d4d4d4;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.28px;
                    border-radius: 16px;
                    opacity: 0.9;

                    &.evil {
                        background-color: rgb(171 61 61);
                    }

                    &.angel {
                        background-color: rgb(217 217 217);
                        color: #0a0a0a;
                    }
                }

                .iconShare {
                    color: red;
                }
            }
        }

        .optionBar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 8px;
            padding: 0 16px;

            .favoriteBtn {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .editBtn {
                height: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 4px;
                white-space: nowrap;
                font-size: 14px;
                font-weight: 700;
            }
        }

        .tabBar {
            padding: 0 32px;

            :global {
                .MuiTabs-flexContainer {
                    column-gap: 32px;
                }

                .MuiButtonBase-root {
                    padding: 0;
                    height: 44px;
                    min-width: initial;
                    min-height: initial;
                    color: #5f5f5f;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: 0.28px;
                    font-family: 'Noto Sans TC', sans-serif;

                    &.Mui-selected {
                        color: #d4d4d4;
                    }
                }

                .MuiTabs-indicator {
                    max-width: 36px;
                    background-color: #d4d4d4;
                }
            }
        }

        .navBar {
            position: relative;
            height: 64px;
            display: flex;
            align-items: center;
            background-color: #1a1a1a;
            box-shadow: 0 1px 0 0 #3b3b3b;
            padding: 8px 16px;
            margin: 0 16px;
            box-sizing: border-box;
            border-radius: 12px;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 1px;
                height: 26px;
                background-color: #525252;
            }

            .income,
            .donation {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                gap: 8px;
            }

            .title {
                line-height: 20px;
                display: flex;
                align-items: center;
                column-gap: 8px;
                color: #d4d4d4;
                font-size: 14px;
                font-weight: 400;
            }

            .value {
                display: flex;
                align-items: center;
                gap: 4px;
                color: #737373;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
}
