.top {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #d4d4d4;
    padding: 16px;
    box-sizing: border-box;

    .title {
        font-size: 20px;
        font-weight: 700;
    }

    .publish {
        font-size: 14px;
        font-weight: 400;
        color: #d4d4d4;
        background: none;
        padding: 0;
    }
}

.addFile {
    padding: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 0;
    flex: 1;
    overflow-y: auto;

    .loading {
        position: absolute;
        inset: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }

    .imgBox {
        position: relative;
        width: 100%;
        height: 280px;
        display: flex;
        justify-content: center;

        .playIcon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            background: rgba(0 0 0 / 60%);
            padding: 16px;
            border-radius: 50%;

            &.hidden {
                display: none;
            }
        }
    }

    .diamond {
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        background: #171717;
        box-shadow: 0 1px 0 0 #3b3b3b;
        border-radius: 12px;

        .left {
            display: flex;
            align-items: center;
            gap: 8px;
            color: #d4d4d4;
        }

        .right {
            display: flex;
            align-items: center;
            gap: 8px;
            color: #737373;
        }
    }
}

.drawer {
    z-index: 1400;

    .container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        padding: 16px 24px;
        background: #171717;
        color: #d4d4d4;
        gap: 8px;
        box-sizing: border-box;

        @media (width >= 513px) {
            width: 512px;
            margin: 0 auto;
        }
    }

    .close {
        position: absolute;
        top: 16px;
        right: 16px;
    }

    .diamond {
        font-size: 20px;
        font-weight: 700;
        margin-top: 8px;
    }

    .confirm {
        width: 100%;
        margin: 32px 0 16px;
        box-sizing: border-box;
    }
}

.modal {
    background: rgba(0 0 0 / 60%);

    .exit {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 288px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        text-align: center;
        padding: 32px;
        background-color: #171717;
        z-index: 100;
        border-radius: 16px;
        box-sizing: border-box;

        .close {
            position: absolute;
            top: 16px;
            right: 16px;
        }

        .title {
            font-weight: 700;
            color: #d4d4d4;
        }

        .content {
            font-size: 14px;
            font-weight: 400;
            color: #525252;
            margin: 8px 0;
        }

        .btn {
            width: 100%;
        }
    }
}
