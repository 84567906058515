.baseTextAreaContainer {
    display: flex;
    flex-direction: column;

    .label {
        display: block;
        font-size: 14px;
        color: #d4d4d4;
        font-weight: 400;
        margin-bottom: 8px;
    }

    .baseTextArea {
        border-radius: 8px;
        background: #171717;
        outline: none;
        color: #d4d4d4;
        padding: 16px;
        font-weight: 400;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #404040;
        flex: 1;

        ::placeholder {
            color: #d4d4d4;
        }
    }
}
