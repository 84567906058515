.baseTagInput {
    display: flex;
    flex-direction: column;

    .label {
        display: block;
        font-size: 14px;
        color: #d4d4d4;
        font-weight: 400;
        margin-bottom: 8px;
    }

    .tagInput {
        border-radius: 8px;
        background: #171717;
        outline: none;
        color: #d4d4d4;
        padding: 12px 16px;
        font-weight: 400;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #404040;
        flex: 1;

        :global {
            input {
                font-size: 16px;
                transform: scale(0.875);
                transform-origin: left center;
            }
        }

        ::placeholder {
            font-size: 14px;
            color: #d4d4d4;
        }
    }

    :global {
        .MuiInputBase-root {
            &::before,
            &::after {
                display: none;
            }
        }

        .MuiInputBase-input {
            color: #d4d4d4;
            font-size: 14px;
            font-weight: 400;
        }

        .MuiSvgIcon-root {
            color: #737373;
        }

        .MuiChip-root {
            border-radius: 16px;
            background: rgb(217 217 217 / 10%);
            color: #d4d4d4;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;

            .MuiSvgIcon-root {
                font-size: 16px;

                &:hover {
                    color: #737373;
                }
            }
        }
    }
}
