.donationRanking {
    width: 100%;

    .header {
        display: flex;
        align-items: flex-end;
        gap: 16px;
        padding: 12px 16px;

        .link {
            color: #737373;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            transition: 0.3s all;

            &:hover {
                color: #d4d4d4;
            }
        }

        .text {
            font-size: 24px;
            color: #d4d4d4;
            font-weight: 700;
            line-height: 32px;
        }
    }

    .dateSelect {
        margin: 16px 0 8px;

        .tabBar {
            margin-bottom: 16px;
        }

        .showDateRange {
            height: 36px;
            padding: 8px 0;
            box-sizing: border-box;
            background-color: #171717;
            text-align: center;
            color: #737373;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }
    }

    .rankingList {
        height: calc(100dvh - 264px);
        display: flex;
        flex-direction: column;
        gap: 8px;
        cursor: pointer;
        overflow: auto;

        .ranking {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 16px;
            box-sizing: border-box;

            .userInfo {
                display: flex;
                align-items: center;
                gap: 16px;
                flex: 1;

                .info {
                    display: flex;
                    flex-direction: column;

                    .name {
                        color: #fafafa;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 24px;
                    }

                    .description {
                        color: #737373;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                    }
                }
            }

            .diamond {
                display: flex;
                align-items: center;
                gap: 4px;

                p {
                    color: #a3a3a3;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }
    }

    .noData {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        flex: 1;

        .noDataTip {
            color: #525252;
            font-size: 16px;
            font-weight: 700;
            line-height: 112.5%;
        }
    }

    .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
    }
}
